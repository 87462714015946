import { EditorPlatformApp, EditorSDK } from '@wix/platform-editor-sdk';
import {
  setNewLayoutStyleForProfileWidget,
  setVerticalLayoutStyleForProfileWidget,
  getLayoutType,
} from './services/styles';
import { toMonitored, initializeMonitoring, log } from './services/monitoring';
import { createAppAPI } from './services/app-api';
import { handleMigrate } from './services/migration';
import { isADIHorizontalEnabled } from './services/experiments';

let editorSDK: EditorSDK;

export const editorScript: EditorPlatformApp = {
  editorReady: async (editorSDK_, _, options) => {
    editorSDK = editorSDK_;
    await initializeMonitoring(editorSDK, options);
    await editorSDK.editor.setAppAPI('', createAppAPI(editorSDK));
    const allowHorizontalInADI = await isADIHorizontalEnabled();
    const isADI = options.origin.type.includes('ADI');

    if (!options.firstInstall) {
      /* Fixes accidental horizontal layouts in ADI */
      const layoutType = await getLayoutType(editorSDK);
      if (isADI && !allowHorizontalInADI && layoutType === 1) {
        log('Fixing horizontal layout in ADI');
        await setVerticalLayoutStyleForProfileWidget(editorSDK);
      }

      return;
    }
    const isHorizontalLayout = !isADI || allowHorizontalInADI;

    if (isHorizontalLayout) {
      await toMonitored('setHorizontalLayout', () => setNewLayoutStyleForProfileWidget(editorSDK));
    }
  },
  handleAction: async (args) => {
    const type = args.type;
    const payload = args.payload;

    try {
      switch (type) {
        case 'migrate':
          return toMonitored('handleMigrate', () => handleMigrate(editorSDK));
        default:
          return;
      }
    } catch (e) {
      console.error(e);
    }
  },
  exports: {
    isHorizontalLayout: () => getLayoutType(editorSDK),
  },
};
